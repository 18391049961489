
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  required,
  maxLength,
  numeric,
  minLength,
  between,
  alphaNum,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { UFDialogText, UFliterals } from "@/utils/literals";
import LoadingMessageDialog from "@/components/dialog/LoadingMessageDialog.vue";
import OTPForm from "@/components/sequence/OTPForm.vue";
import clientCache from "@/utils/cacheUtils";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import OTPVerifyForm from "@/components/sequence/OTPVerifyForm.vue";
import { ActionContext } from "vuex";
import sequenceRepository from "@/repository/sequence";

@Component({
  name: "SequenceJoiningPage",
  components: { OTPVerifyForm, OTPForm, LoadingMessageDialog },
  mixins: [validationMixin],
  validations: {
    otp: {
      required,
      maxLength: maxLength(6),
      minLength: minLength(6),
    },
    sequenceCode: {
      required,
      maxLength: maxLength(5),
      minLength: minLength(5),
      alphaNum,
    },
    username: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(3),
    },
    gender: { required },
    birth: {
      required,
      numeric,
      maxLength: maxLength(2),
      minLength: minLength(1),
      between: between(1, 31),
    },
    number: {
      required,
      numeric,
      maxLength: maxLength(2),
      minLength: minLength(2),
      between: between(0, 99),
    },
  },
})
export default class SequenceJoiningPage extends Vue {
  @Prop({ type: String, required: true })
  readonly code: string | undefined;

  private userError = false;
  private anonError = false;
  private continuer = false;
  private emailSent = false;
  private alreadyHaveCode = false;
  private sequenceCode = "";
  private savedOtp = "";
  private number = "";
  private username = "";
  private birth = "";
  private gender = null;
  private genders = [
    { value: "a", text: "A (Anonyme)" },
    { value: "m", text: "M (Masculin)" },
    { value: "f", text: "F (Féminin)" },
  ];
  private isLoading = false;
  private fetchStatusTriggerId = 0;
  private pendingRequest = false;
  private disabledUsername = false;
  private dialogStatusText = UFDialogText["joiningSequence"];
  progress = true;

  created(): void {
    if (this.code) {
      this.sequenceCode = this.code;
    }
    let clientCode = clientCache.get(`sequence:${this.sequenceCode}:requested`);
    if (clientCode) {
      this.isLoading = true;
      this.fetchStatusTriggerId = this.triggerStatus(clientCode);
    }
  }

  mounted(): void {
    if (!this.isAuthenticated) {
      this.$store.commit("SET_NOTIFICATIONS", []);
    }
  }

  get otp(): string {
    return this.savedOtp;
  }

  set otp(newOTP: string) {
    clientCache.cache("otp", newOTP);
    this.savedOtp = newOTP;
  }

  get dialogText(): string {
    return this.dialogStatusText;
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.number.$dirty) return errors;
    !this.$v.number.minLength &&
      errors.push(UFFormValidationMessage.minLengthNum(2));
    !this.$v.number.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthNum(2));
    !this.$v.number.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get genderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.gender.$dirty) return errors;
    !this.$v.gender.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get sequenceCodeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.sequenceCode.$dirty) return errors;
    !this.$v.sequenceCode.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.sequenceCode.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(5));
    !this.$v.sequenceCode.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(3));
    !this.$v.username.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(10));
    !this.$v.username.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get birthErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.birth.$dirty) return errors;
    !this.$v.birth.minLength &&
      errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.birth.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthNum(2));
    this.$v.birth.$model > 31 && errors.push("Le jour maximum est 31.");
    !this.$v.birth.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get otpErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.otp.$dirty) return errors;
    !this.$v.otp.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(6));
    !this.$v.otp.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(6));
    !this.$v.otp.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get isEnabled(): boolean {
    return !this.$v.$invalid;
  }

  async getSequenceUsers(otp: string): Promise<void> {
    console.log(otp);
    const res = await this.$store.dispatch("sequence/getRequestingUsers", {
      sequenceCode: this.code,
      next: (approved: string[]) => {
        return approved;
      },
    });

    return res;
  }

  async getRequestingUsers(sequenceCode: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      sequenceRepository
        .getRequestingUsers(sequenceCode)
        .then((approved: string[]) => {
          resolve(approved);
        })
        .catch((e) => {
          reject(e);
        });
    });
    console.log("fin de getRequestingUsers");
    return promise;
  }

  async submit(): Promise<void> {
    this.$v.$touch();
    this.userError = false;
    this.anonError = false;
    // verifier si un nom d'utilisateur est le meme et code d'anonymat
    this.continuer = false;
    //
    let requestingUsers = await this.getRequestingUsers(this.sequenceCode);
    requestingUsers = requestingUsers.map((user: string) => JSON.parse(user));
    let clientCode = `${this.sequenceCode}${this.gender}${this.birth}${this.number}`;
    let existing = requestingUsers.find(
      (e: any) => e.username == this.username || e.code == clientCode
    );
    if (existing) {
      if (existing.username == this.username) {
        this.userError = true;
      } else {
        this.anonError = true;
      }
      return;
    }

    if (!this.$v.$invalid) {
      this.$store.dispatch("sequence/requestToJoin", {
        sequenceCode: this.sequenceCode,
        clientCode,
        username: this.username,
        otp: this.otp,
        email: clientCache.get("otp_email"),
        next: (queuePosition: number) => {
          clientCache.cache(
            `sequence:${this.sequenceCode}:requested`,
            `${this.sequenceCode}${this.gender}${this.birth}${this.number}`
          );
          this.isLoading = true;
          this.fetchStatusTriggerId = this.triggerStatus(clientCode);
          console.log(`You're number is ${queuePosition}.`);
        },
      });
    }
  }

  requestToJoin(otp: string): void {
    if (this.alreadyHaveCode) {
      console.log(otp);
      this.$store.dispatch("sequence/requestToJoin", {
        sequenceCode: this.code,
        clientCode: clientCache.get("code"),
        username: this.username,
        otp: otp,
        email: clientCache.get("otp_email"),
        registered: true,
        next: (queuePosition: number) => {
          this.joinRequestedHandler(queuePosition, clientCache.get("code"));
        },
      });
    }
  }

  joinRequestedHandler(queuePosition: number, code: string): void {
    clientCache.cache(
      `sequence:${this.sequenceCode}:requested`,
      `${this.sequenceCode}${this.gender}${this.birth}${this.number}`
    );
    this.isLoading = true;
    this.fetchStatusTriggerId = this.triggerStatus(code);
    console.log(`You're number is ${queuePosition}.`);
  }

  beforeRouteLeave(): void {
    if (this.fetchStatusTriggerId)
      window.clearInterval(this.fetchStatusTriggerId);
  }

  beforeDestroy(): void {
    if (this.fetchStatusTriggerId)
      window.clearInterval(this.fetchStatusTriggerId);
  }

  clear(): void {
    this.$v.$reset();
    this.sequenceCode = "";
    this.username = "";
    this.birth = "";
    this.number = "";
    this.gender = null;
  }

  getUFLabel(key: string): string {
    return UFliterals[key] || "";
  }

  triggerStatus(clientCode: string): number {
    return window.setInterval(() => {
      if (!this.pendingRequest) {
        this.pendingRequest = true;
        this.$store.dispatch("sequence/getMyStatus", {
          code: this.code,
          userCode: clientCode,
          email: clientCache.get("otp_email"),
          next: (message: {
            status: string;
            sequence_id?: string;
            uuid?: string;
          }) => {
            this.pendingRequest = false;
            this.dialogStatusText = this.getUFLabel(message.status);
            if (message.status === "approved") {
              if (message.uuid && message.code) {
                clientCache.cache("auth_uuid", message.uuid);
              } else {
                this.$store.dispatch("sequence/fetchMyAuthId", {
                  sequenceCode: this.sequenceCode,
                  email: clientCache.get("otp_email"),
                  next: (message) => {
                    if (message.uuid && message.code) {
                      clientCache.cache("auth_uuid", message.uuid);
                    }
                  },
                });
              }
              return;
            }
            if (message.status === "dispatched") {
              let auth_uuid = clientCache.get("auth_uuid");
              if (!auth_uuid) {
                this.$store.dispatch("sequence/fetchMyAuthId", {
                  sequenceCode: this.sequenceCode,
                  email: clientCache.get("otp_email"),
                  next: (mess) => {
                    if (mess.uuid) {
                      clientCache.cache("auth_uuid", mess.uuid);
                      let auth_uuid = mess.uuid;
                      this.$store.dispatch("getTokenFromQuickCreds", {
                        credentials: {
                          email: clientCache.get("otp_email"),
                          auth_uuid,
                        },
                        sequenceCode: this.sequenceCode,
                        next: () => {
                          this.$router.push({
                            name: "sequence",
                            params: {
                              id: message.sequence_id || "",
                            },
                          });
                          clientCache.clear("otp");
                          clientCache.clear("auth_uuid");
                          clientCache.clear("otp_email");
                          clientCache.clear(
                            `sequence:${this.sequenceCode}:requested`
                          );
                        },
                      });
                    }
                  },
                });
                return;
              }
              this.$store.dispatch("getTokenFromQuickCreds", {
                credentials: {
                  email: clientCache.get("otp_email"),
                  auth_uuid,
                },
                sequenceCode: this.sequenceCode,
                next: () => {
                  clientCache.clear("otp");
                  clientCache.clear("auth_uuid");
                  clientCache.clear("otp_email");
                  clientCache.clear(`sequence:${this.sequenceCode}:requested`);
                  this.$router.push({
                    name: "sequence",
                    params: {
                      id: message.sequence_id || "",
                    },
                  });
                },
              });
            }
            if (message.status === "denied") {
              clientCache.clear(`sequence:${this.sequenceCode}:requested`);
              this.progress = false;
            }
          },
        });
      }
    }, 2000);
  }

  otpFormHandler(emailSent: boolean, username?: string, code?: string): void {
    // TODO : check if user has code
    //  If not or no username display code form
    //  If code diplay only OTP check form
    if (code) {
      this.alreadyHaveCode = true;
    }
    if (username) {
      this.username = username;
      this.disabledUsername = true;
    }
    this.emailSent = emailSent;
  }
}
