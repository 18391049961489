
import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { UFliterals } from "@/utils/literals";
import clientCache from "@/utils/cacheUtils";

@Component({
  name: "OTPVerifyForm",
  mixins: [validationMixin],
  validations: {
    otp: {
      required,
      maxLength: maxLength(6),
      minLength: minLength(6),
    },
  },
})
export default class OTPVerifyForm extends Vue {
  @Prop({ type: Function, required: true })
  responseHandler!: CallableFunction;
  @Prop({ type: String, required: true })
  sequenceCode!: string;
  otp = "";

  get otpErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.otp.$dirty) return errors;
    !this.$v.otp.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(6));
    !this.$v.otp.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(6));
    !this.$v.otp.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }
  getUFLabel(key: string): string {
    return UFliterals[key] || "";
  }

  verifyOTP(): void {
    this.responseHandler(this.otp);
  }
}
